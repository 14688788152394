import { Link } from "react-router-dom";
import { useState, useRef, useCallback, useEffect } from "react";
import * as Pitchfinder from "pitchfinder";

import { pitchMap } from "../utils";
import { techMap } from "../utils";
import { projects as projectData } from "../_dataFile.js";

import { IoIosArrowRoundBack } from "react-icons/io";
import "./Tuner.css";

const Tags = () => {
    return (
        <div className="my-1 flex flex-wrap justify-center gap-2 mt-8 md:mt-4 p-4 md:px-8">
            {projectData[3].tags.map((eachTag) => {
                const bgColor = techMap[eachTag].color;
                const tagName = techMap[eachTag].name;
                const TagIcon = techMap[eachTag].Icon;

                return (
                    <div
                        key={eachTag}
                        className="rounded-3xl py-1 px-4 flex items-center gap-2 shadow-2xl shadow-black"
                        style={{ background: bgColor }}
                    >
                        <span className="text-white font-thin text-xs md:text-sm">{tagName}</span>
                        {TagIcon}
                    </div>
                );
            })}
        </div>
    );
};

const getPitch = (inputFrequency) => {
    console.log({ inputFrequency });
    if (!inputFrequency) {
        return { isValidPitch: false };
    }

    // 1.05916; difference between each half note
    const SCALE = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];
    let currentOctave;

    // find which octave the frequency belongs to
    Object.keys(pitchMap).forEach((eachOctave) => {
        const thisRange = eachOctave.split(" to ");
        const min = Number(thisRange[0]);
        const max = Number(thisRange[1]);

        if (inputFrequency >= min && inputFrequency <= max) {
            currentOctave = eachOctave;
        }
    });

    if (!currentOctave) {
        console.log("ERROR: invalid range -- input frequency must be between 16 - 8000 hz range. Yours was: ", inputFrequency);
        return { isValidPitch: false };
    }

    const frequencyIntervals = Object.values(pitchMap[currentOctave]);
    const baseFrequency = frequencyIntervals[0];
    const maxFrequency = frequencyIntervals[frequencyIntervals.length - 1];

    let closestPitch;
    let status; // sharp || flat || inTune

    for (let i = 0; i < frequencyIntervals.length; i++) {
        const currentFrequency = frequencyIntervals[i];
        const nextFrequency = frequencyIntervals[i + 1];

        if (inputFrequency < baseFrequency) {
            // must be flat and must be at the beginning of the range
            closestPitch = "C";
            status = "flat";
            break;
        } else if (inputFrequency > maxFrequency) {
            // must be sharp and must be at the end of the range
            closestPitch = "B";
            status = "sharp";
            break;
        } else if (inputFrequency >= currentFrequency && inputFrequency <= nextFrequency) {
            const distanceFromMax = nextFrequency - inputFrequency;
            const distanceFromMin = inputFrequency - baseFrequency;

            // We use the mid-point to determine when to tune up past the [i] note -> [i + 1] note.
            const midPoint = Math.abs(currentFrequency + (nextFrequency - currentFrequency) / 2);

            const frequencyWaveLength = nextFrequency - baseFrequency;

            if (Math.abs(distanceFromMax / frequencyWaveLength) * 100 < 3) {
                status = "inTune";
                closestPitch = SCALE[i + 1];
            } else if (Math.abs(distanceFromMin / frequencyWaveLength) * 100 < 3) {
                status = "inTune";
                closestPitch = SCALE[i];
            } else if (inputFrequency < nextFrequency && inputFrequency > baseFrequency && inputFrequency > midPoint) {
                status = "flat";
                closestPitch = SCALE[i + 1];
            } else if (inputFrequency > currentFrequency && inputFrequency < nextFrequency && inputFrequency < midPoint) {
                status = "sharp";
                closestPitch = SCALE[i];
            }
            break;
        }
    }

    return {
        closestPitch,
        status,
        isValidPitch: true,
        inputFrequency,
    };
};

const Tuner = () => {
    const [isOn, setIsOn] = useState(false);
    const [pitch, setPitch] = useState("-");
    const [isFlat, setIsFlat] = useState(false);
    const [isSharp, setIsSharp] = useState(false);
    const [inTune, setInTune] = useState(false);

    const audioContext = useRef(null);
    const mediaStreamSource = useRef(null);
    const meter = useRef(null);

    const resetTuner = useCallback(() => {
        setPitch("-");
        setIsFlat(false);
        setIsSharp(false);
        setInTune(false);
    }, []);

    const startListeningForPitch = useCallback(
        (myAudioBuffer) => {
            if (!isOn) {
                const float32Array = myAudioBuffer.getChannelData(0);
                const detectPitch = Pitchfinder.AMDF();
                const audioPitch = detectPitch(float32Array);
                if (!audioPitch) {
                    return;
                }
                const result = getPitch(audioPitch);
                if (result.isValidPitch) {
                    resetTuner();
                    setPitch(result.closestPitch || "-");

                    if (result.status === "inTune") {
                        setInTune(true);
                    } else if (result.status === "flat") {
                        setIsFlat(true);
                    } else if (result.status === "sharp") {
                        setIsSharp(true);
                    }
                }
            } else {
                resetTuner();
                setPitch("");
            }
        },
        [isOn, resetTuner]
    );
    const createAudioMeter = (context) => {
        const processor = context.createScriptProcessor(512);
        processor.onaudioprocess = (e) => startListeningForPitch(e.inputBuffer);
        processor.connect(context.destination);
        return processor;
    };

    const toggleButton = (e) => {
        e.preventDefault();

        // Check for AudioContext support
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        if (!AudioContext) {
            window.alert(
                "Error accessing your microphone. Please ensure your microphone is enabled, and or your browser is compatible. You can find out which browsers are supported at https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API"
            );
            return;
        }

        if (!isOn) {
            // Create a new AudioContext instance if supported
            try {
                audioContext.current = new AudioContext(); // Use the correct AudioContext based on availability

                navigator.mediaDevices
                    .getUserMedia({ audio: true })
                    .then((stream) => {
                        mediaStreamSource.current = audioContext.current.createMediaStreamSource(stream);
                        meter.current = createAudioMeter(audioContext.current);
                        mediaStreamSource.current.connect(meter.current);
                    })
                    .catch((error) => {
                        console.error("Error accessing microphone: ", error);
                        window.alert(
                            "Error accessing your microphone. Please ensure your microphone is enabled, and or your browser is compatible. You can find out which browsers are supported at https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API"
                        );
                    });
            } catch (error) {
                console.error("Error creating AudioContext: ", error);
                window.alert("There was an error initializing the audio context.");
            }
        } else {
            // Stop the AudioContext when turning off the tuner
            if (audioContext.current) {
                audioContext.current.close();
            }
            audioContext.current = null;
            mediaStreamSource.current = null;
            meter.current = null;
            resetTuner();
        }

        setIsOn(!isOn);
    };

    useEffect(() => {
        return () => {
            if (audioContext.current && audioContext.current.close) {
                audioContext.current.close();
            }
        };
    }, []);

    return (
        <div className="min-h-[100dvh] pb-32 w-full bg-zinc overflow-x-hidden md:overflow-y-hidden">
            <div className="bg-white fixed bottom-0 left-0 right-0 md:bottom-[unset] md:left-[unset] md:right-[unset]  md:relative z-30 shadow-2xl px-1 py-6 md:p-6 pt-8">
                <div className="flex page-clamp">
                    <Link to="/?projectId=6" className="text-green-600 flex items-center page-clamp ">
                        <IoIosArrowRoundBack size={"2rem"} /> Back <span className="hidden sm:block">to Home</span>
                    </Link>
                    <div className="ml-auto">
                        <div className="">
                            <a href="#projects" className="btn blue text-black shadow-emerald-500/40 whitespace-nowrap">
                                GitHub &nbsp; &nbsp;{"  </>"}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="min-h-[800px] flex flex-col order- pt-[24px] md:pt-[36px] overflow-x-hidden page-clamp text-center">
                <h2 className="font-semibold order-1 text-3xl md:text-5xl pt-4">
                    Digital Guitar&nbsp;
                    <span className="text-green-500">Tuner</span>.
                </h2>

                <div className="tuner-container order-2 md:order-3 mx-auto max-h-[600px] shadow-slate-500 shadow-2xl mt-6">
                    <section className="tuner-main h-[400px] px-2">
                        <div>
                            <div id="flat-indicator" className={`triangle ${inTune ? "in-tune" : isFlat ? "on" : ""}`}></div>
                        </div>
                        <div id="pitch">{isOn ? pitch : ""}</div>
                        <div>
                            <div id="sharp-indicator" className={`triangle ${inTune ? "in-tune" : isSharp ? "on" : ""}`}></div>
                        </div>
                    </section>
                    <header className="tuner">
                        <span className="flex-row justify-center my-3 mx-auto">
                            <h1>TU-22</h1>
                            <div>a</div>
                        </span>
                        <p className="pb-4">DIGITAL GUITAR TUNER</p>
                    </header>
                    <footer className="h-[80px]">
                        <button onClick={toggleButton} id="on-button" className="on-button relative">
                            <h6 style={{ margin: "auto", position: "absolute", top: "40%", left: 0, bottom: "60%", right: 0 }}>I / O</h6>
                            <div className="led-box absolute right-4">
                                <div id="led-light-on-indicator" className={`led-red ${isOn ? "on" : ""}`}></div>
                            </div>
                        </button>
                    </footer>
                </div>
                <div className="order-3 md:order-2">
                    <Tags />
                </div>
            </div>
        </div>
    );
};

export default Tuner;
