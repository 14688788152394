import { BiLogoNodejs } from "react-icons/bi";
import { IoLogoElectron } from "react-icons/io5";
import { FaHtml5 } from "react-icons/fa";
import { SiExpress } from "react-icons/si";
import { TbApi } from "react-icons/tb";
import { IoLogoJavascript } from "react-icons/io5";
import { FaCss3Alt } from "react-icons/fa";
import { FaRegFileCode } from "react-icons/fa";
import { FaFileAudio } from "react-icons/fa";
import { SiTypescript } from "react-icons/si";
import { SiCheerio } from "react-icons/si";
import { FaVuejs } from "react-icons/fa";
import { FaFigma } from "react-icons/fa6";
import { SiTestinglibrary } from "react-icons/si";
import { SiCypress } from "react-icons/si";
import { SiAmazonsimpleemailservice } from "react-icons/si";
import { SiMongodb } from "react-icons/si";
import { MdScheduleSend } from "react-icons/md";
import { SiReact } from "react-icons/si";
import { RiTailwindCssFill } from "react-icons/ri";
import { TbChartBubble } from "react-icons/tb";

export const techMap = {
    node: {
        Icon: <BiLogoNodejs fill={"white"} size={"20px"} />,
        color: "#417E38",
        name: "Node JS",
    },
    electron: {
        Icon: <IoLogoElectron fill={"#9feaf9"} size={"20px"} />,
        color: "#1B1C26",
        name: "Electron JS",
    },
    html: {
        Icon: <FaHtml5 fill={"#f16a32"} size={"20px"} />,
        color: "#323437",
        name: "HTML 5",
    },
    react: {
        Icon: <SiReact fill={"#5ec6dd"} size={"20px"} />,
        color: "#323437",
        name: "React JS",
    },
    express: {
        Icon: <SiExpress fill={"#f3e02a"} size={"20px"} />,
        color: "#323437",
        name: "Express JS",
    },
    "API Integration": {
        Icon: <TbApi fill={"#fff"} size={"20px"} />,
        color: "#598011",
        name: "API Integration",
    },
    "web audio API": {
        Icon: <FaFileAudio fill={"#fff"} size={"20px"} />,
        color: "#801175",
        name: "Web Audio API",
    },
    "file encoding": {
        Icon: <FaRegFileCode fill={"#fff"} size={"20px"} />,
        color: "#798011",
        name: "File Encoding",
    },
    js: {
        Icon: <IoLogoJavascript fill={"#f3e02a"} size={"20px"} />,
        color: "#323437",
        name: "Javascript",
    },
    css: {
        Icon: <FaCss3Alt fill={"#3053e5"} size={"20px"} />,
        color: "#323437",
        name: "CSS 3",
    },
    tailwind: {
        Icon: <RiTailwindCssFill fill={"#25b4ba"} size={"20px"} />,
        color: "#323437",
        name: "Tailwind",
    },
    typescript: {
        Icon: <SiTypescript fill={"#5e98d5"} size={"20px"} />,
        color: "#323437",
        name: "Typescript",
    },
    vue: {
        Icon: <FaVuejs fill={"#33bb87"} size={"20px"} />,
        color: "#323437",
        name: "Vue JS",
    },
    cheerio: {
        Icon: <SiCheerio fill={"#fff"} size={"20px"} />,
        color: "#e9902b",
        name: "Cheerio JS",
    },
    "react testing library": {
        Icon: <SiTestinglibrary fill={"#fb4d4d"} size={"20px"} />,
        color: "#323437",
        name: "React Testing Library",
    },
    cypress: {
        Icon: <SiCypress fill={"#62d2a4"} size={"20px"} />,
        color: "#323437",
        name: "E2E testing with Cypress",
    },
    "UI/UX": {
        Icon: <FaFigma fill={"#fff"} size={"20px"} />,
        color: "#cfc511",
        name: "UX & UI in Figma",
    },
    nodemailer: {
        Icon: <SiAmazonsimpleemailservice fill={"#fff"} size={"20px"} />,
        color: "#17855e",
        name: "Nodemailer Integration",
    },
    mongodb: {
        Icon: <SiMongodb fill={"#5eb14f"} size={"20px"} />,
        color: "#323437",
        name: "Mongo DB via Mongoose",
    },
    "node-cron": {
        Icon: <MdScheduleSend fill={"white"} size={"20px"} />,
        color: "#541785",
        name: "Automated job by Node Cron",
    },
    visJs: {
        Icon: <TbChartBubble fill={"white"} size={"20px"} />,
        color: "blue",
        name: "VisJs",
    },
};

export const pitchMap = {
    "16 to 31.9": { C: 16, "C#": 17, D: 18, "D#": 20, E: 21, F: 22, "F#": 23, G: 25, "G#": 26, A: 28, "A#": 29, B: 31 },
    "32 to 63": { C: 33, "C#": 35, D: 37, "D#": 39, E: 41, F: 44, "F#": 46, G: 49, "G#": 52, A: 55, "A#": 58, B: 62 },
    "64 to 127.4": { C: 65, "C#": 69, D: 73, "D#": 78, E: 82, F: 87, "F#": 93, G: 98, "G#": 104, A: 110, "A#": 117, B: 124 },
    "127.5 to 254.4": { C: 131, "C#": 139, D: 147, "D#": 156, E: 165, F: 175, "F#": 185, G: 196, "G#": 208, A: 220, "A#": 233, B: 247 },
    "254.5 to 509.4": { C: 262, "C#": 278, D: 294, "D#": 311, E: 330, F: 349, "F#": 370, G: 392, "G#": 415, A: 440, "A#": 466, B: 494 },
    "509.5 to 1017.4": { C: 523, "C#": 554, D: 587, "D#": 622, E: 659, F: 699, "F#": 740, G: 784, "G#": 831, A: 880, "A#": 932, B: 988 },
    "1017.5 to 2034.4": {
        C: 1047,
        "C#": 1109,
        D: 1175,
        "D#": 1245,
        E: 1319,
        F: 1397,
        "F#": 1475,
        G: 1568,
        "G#": 1661,
        A: 1760,
        "A#": 1865,
        B: 1976,
    },
    "2034.5 to 4068.4": {
        C: 2093,
        "C#": 2218,
        D: 2349,
        "D#": 2489,
        E: 2637,
        F: 2794,
        "F#": 2960,
        G: 3136,
        "G#": 3322,
        A: 3520,
        "A#": 3729,
        B: 3951,
    },
    "4068.5 to 8000": {
        C: 4186,
        "C#": 4435,
        D: 4699,
        "D#": 4978,
        E: 5274,
        F: 5588,
        "F#": 5920,
        G: 6272,
        "G#": 6645,
        A: 7040,
        "A#": 7459,
        B: 7902,
    },
};
