import React from "react";

import laptopImg from "../_images/laptop-frame.png";

const Laptop = ({ children }) => {
    return (
        <div className="lg:relative w-full border-pink-200">
            <div className="lg:relative lg:bottom-0 left-0 right-0 lg:max-w-[900px] lg:min-w-[900px] mx-auto">
                <img
                    src={laptopImg}
                    width={900}
                    height={"auto"}
                    alt="laptop hero container"
                    className="hidden lg:block "
                    style={{ zIndex: 20, position: "absolute", bottom: 0, left: 0, right: 0 }}
                />
                <div className="hidden h-0 lg:block black-bg py-8 px-3 lg:absolute bottom-[75px] lg:h-[440px] border z-0 left-[112px] right-[112px]"></div>
                <div className="relative lg:absolute lg:top-[-490px] bg-transparent lg:left-[112px] lg:right-[112px] z-40 mt-8">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Laptop;
