import { Link } from "react-router-dom";

import Footer from "../_components/Footer";
import ProjectTable from "../_components/ProjectTable";

import { IoIosArrowRoundBack } from "react-icons/io";

const Projects = () => {
    return (
        <div>
            <div>
                <Link to="/" className="text-green-600 flex items-center py-12 page-clamp">
                    <IoIosArrowRoundBack size={"2rem"} /> Back to Home
                </Link>
            </div>
            <section id="projects" className="flex md:mt-20 mb-12 h-auto flex-col items-center justify-between">
                <div className="max-w-5xl text-center mx-auto">
                    <h2 className="font-semibold text-3xl md:text-6xl pt-4">
                        Sample <span className="text-green-500">Projects</span>.
                    </h2>
                    <p className="mt-4">Browse projects I have created & their code.</p>
                    <ProjectTable />
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Projects;
