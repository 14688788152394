"use client";
import { useState, useEffect } from "react";

import LanguageSwitcher from "./LanguageSwitcher.js";

import { RxHamburgerMenu } from "react-icons/rx";
import { FaGithub } from "react-icons/fa";
import { LuMailQuestion } from "react-icons/lu";
import { IoClose } from "react-icons/io5";

const NavMenu = () => {
    const [navOpen, setNavOpen] = useState(false);
    return (
        <>
            <div className="w-full mx-auto ">
                <div className="flex  justify-between items-center">
                    <RxHamburgerMenu size={"45px"} className="cursor-pointer" onClick={() => setNavOpen(true)} />
                    <div className="hidden md:flex gap-8">
                        {/* <a target="_blank" rel="noopener noreferrer" href="https://github.com/RL4444">
                            <FaGithub size={24} />
                        </a> */}
                        {/* <a target="_blank" rel="noopener noreferrer" href="mailto:roryldeveloper@gmail.com">
                            <LuMailQuestion size={24} />
                        </a> */}
                        {/* <div className="ml-8">
                        <LanguageSwitcher />
                        </div> */}
                    </div>
                </div>
            </div>
            {navOpen ? (
                <div className="fixed w-[100vw] sm:w-[50vw] lg:w-[30vw] h-[100dvh] black-bg top-0 left-0 right-0 z-50 shadow-xl shadow-slate-100">
                    <div onClick={() => setNavOpen(false)}>
                        <IoClose color="#fff" size={"60px"} className="lg:hidden cursor-pointer" />
                    </div>
                    <div className="flex p-24 flex-col items-end gap-6">
                        <div onClick={() => setNavOpen(false)}>
                            <IoClose color="#fff" size={"60px"} className="hidden lg:block cursor-pointer" />
                        </div>
                        <a
                            onClick={() => setNavOpen(false)}
                            href="#projects"
                            className="text-slate-200 hover:text-green-500 text-2xl mt-10 font-extralight nav-item"
                        >
                            PROJECTS
                        </a>
                        <a
                            onClick={() => setNavOpen(false)}
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://github.com/RL4444"
                            className="text-slate-200 hover:text-green-500 text-2xl font-extralight nav-item"
                        >
                            GITHUB
                        </a>
                        {/* <a
                            onClick={() => setNavOpen(false)}
                            href="mailTo:roryldeveloper@gmail.com"
                            className="text-slate-200 hover:text-green-500 text-2xl font-extralight nav-item"
                        >
                            CONTACT
                        </a> */}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default NavMenu;
