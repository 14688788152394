"use client";
import React, { useEffect, useState } from "react";

export default function FadeIn(props) {
    const [maxIsVisible, setMaxIsVisible] = useState(0);
    const transitionDuration = props.transitionDuration || 400;
    const delay = props.delay || 200;
    const ChildTag = props.childTag || "div";
    const visible = typeof props.visible === "undefined" ? true : props.visible;

    useEffect(() => {
        const count = React.Children.count(props.children);

        if (!visible || count === maxIsVisible) {
            // We're done updating maxVisible, notify when animation is done
            const timeout = setTimeout(() => {
                if (props.onComplete) props.onComplete();
            }, transitionDuration);
            return () => clearTimeout(timeout);
        }

        // Move maxIsVisible toward count
        const increment = count > maxIsVisible ? 1 : -1;
        const timeout = setTimeout(() => {
            setMaxIsVisible(maxIsVisible + increment);
        }, delay);
        return () => clearTimeout(timeout);
    }, [delay, maxIsVisible, visible, transitionDuration, props]);

    return (
        <>
            {React.Children.map(props.children, (child, i) => {
                return (
                    <ChildTag
                        className={props.childClassName}
                        style={{
                            transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`,
                            transform: maxIsVisible > i ? "none" : "translateY(20px)",
                            opacity: maxIsVisible > i ? 1 : 0,
                        }}
                    >
                        {child}
                    </ChildTag>
                );
            })}
        </>
    );
}
