import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./views/Home";
import Projects from "./views/Projects";
import Tuner from "./views/Tuner";

function App() {
    return (
        <div>
            <Routes>
                <Route path="/" index element={<Home />}>
                    {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
                    {/* <Route path="*" element={<NoMatch />} /> */}
                </Route>
                <Route path="/projects" element={<Projects />} />
                <Route path="/tuner" element={<Tuner />} />
            </Routes>
        </div>
    );
}

export default App;
