"use client";
import { useState, useEffect } from "react";
import ProjectCard from "./ProjectCard.js";
import { projects as projectData } from "../_dataFile.js";
import ProjectOverlayGallery from "./ProjectOverlayGallery.js";
import { useSearchParams } from "react-router-dom";

const ProjectTable = () => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState({});
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) {
            setProjects([...projectData]);
            setLoaded(true);
        }
    }, [loaded]);

    useEffect(() => {
        if (searchParams.has("projectId") && loaded) {
            let clickedProject = null;
            if (!selectedProjectId) {
                const project = [...projectData].find((eachProj) => eachProj.id === Number(searchParams.get("projectId")));
                setSelectedProject(project);
                setSelectedProjectId(project.id);

                setGalleryOpen(true);
                clickedProject = document.getElementById(`project-card-${project.id}`);
                document.body.style = "overflow: hidden;";
            }

            const project = [...projectData].find((eachProj) => eachProj.id === Number(selectedProjectId));
            setSelectedProject(project);

            if (clickedProject) {
                clickedProject.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            }
        }
    }, [searchParams, projects, selectedProjectId, loaded]);

    const handleGalleryOpen = (projectId) => {
        setSearchParams({ projectId });
        setSelectedProjectId(projectId);
        document.body.style = "overflow: hidden;";
        setGalleryOpen(true);
    };
    const closeModal = () => {
        document.body.style = "";
        setGalleryOpen(false);
        window.history.pushState({}, document.title, "/");
    };

    return (
        // <div id="project-table" className="flex justify-center pt-12 flex-wrap gap-1 projects-cols">
        <div id="project-table" className="pt-12 md:pt-24 pb-12 page-clamp mx-auto gap-4 colums-1 sm:columns-2 lg:columns-3 gap-y-4 ">
            {projects.length &&
                projects.map((eachProject) => {
                    return (
                        <ProjectCard
                            onClick={() => handleGalleryOpen(eachProject.id)}
                            cssId={`project-card-${eachProject.id}`}
                            project={eachProject}
                            key={eachProject.id}
                        />
                    );
                })}
            {galleryOpen && selectedProject && selectedProject.id ? (
                <>
                    <ProjectOverlayGallery
                        closeModal={closeModal}
                        project={selectedProject}
                        currentImageId={`project-card-${selectedProject.id}`}
                    />
                </>
            ) : null}
        </div>
    );
};

export default ProjectTable;
