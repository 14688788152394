const ProjectCard = ({ project, onClick, cssId }) => {
    return (
        <div className="py-2">
            <div id={cssId} onClick={onClick} className="bg-black w-[300px] mx-auto project-card-wrapper overflow-hidden cursor-pointer">
                <div className={`my-auto  object-cover ${project.paddedCard ? "p-20 md:p-8" : "mx-auto"}`}>
                    <img src={project.imgSrc} alt={project.title + " cover"} />
                </div>
                <div className="bg-white py-3 px-1 mt-auto overflow-hidden">
                    <h5 className="font-thin uppercase line-clamp-2  text-ellipsis barlow">{project.title}</h5>
                </div>
            </div>
        </div>
    );
};

export default ProjectCard;
